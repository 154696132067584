import styled, { css } from 'styled-components'
import colors from 'Assets/colors'

export const Container = styled.div`
  background-color: ${colors.gray98b};
  padding: 2.5rem 1.25rem 1rem;
  height: calc(100vh - 168px);

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}
  overflow: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1rem;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 24px;
`
