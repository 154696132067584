import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CartIcon } from 'Assets/Icons/Cart.svg'
import { ReactComponent as CartRepeatIcon } from 'Assets/Icons/CartRepeatBlack.svg'
import { ReactComponent as ComplaintIcon } from 'Assets/Icons/Complaint.svg'
import { ReactComponent as DownloadIcon } from 'Assets/Icons/Descargar.svg'
import { ReactComponent as QaIcon } from 'Assets/Icons/QaOk.svg'
import { ORDER_STATUS } from 'Config/constants'

import * as S from './styled'

const ActionsCell = ({
  row,
  onDownloadOrderClick,
  onNoComplianceClick,
  onRepeatOrderClick,
  onViewProductsClick,
  onViewQAClick,
}) => {
  const { t } = useTranslation()

  const getClickCallback = useCallback(callback => () => callback(row), [row])

  const ActionButton = useCallback(
    ({ title, Icon, onClick, className = '' }) => (
      <S.Button className={className} title={title} onClick={getClickCallback(onClick)}>
        <Icon width={18} height={18} />
      </S.Button>
    ),
    [getClickCallback]
  )
  return (
    <S.Cell>
      <ActionButton title={t`repeatOrder`} Icon={CartRepeatIcon} onClick={onRepeatOrderClick} />
      <ActionButton className='download-btn' title={t`download`} Icon={DownloadIcon} onClick={onDownloadOrderClick} />
      <ActionButton title={t`seeProducts`} Icon={CartIcon} onClick={onViewProductsClick} />

      {row.state === ORDER_STATUS.RC && (
        <>
          <ActionButton title={t`noCompliance`} Icon={ComplaintIcon} onClick={onNoComplianceClick} />
          <ActionButton title={t`seeQA`} Icon={QaIcon} onClick={onViewQAClick} />
        </>
      )}
      {row.state === ORDER_STATUS.PR && (
        <>
          <ActionButton title={t`noCompliance`} Icon={ComplaintIcon} onClick={onNoComplianceClick} />
        </>
      )}
    </S.Cell>
  )
}

ActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDownloadOrderClick: PropTypes.func,
  onNoComplianceClick: PropTypes.func,
  onRepeatOrderClick: PropTypes.func,
  onViewProductsClick: PropTypes.func,
  onViewQAClick: PropTypes.func,
}

ActionsCell.defaultProps = {
  onDownloadOrderClick: () => undefined,
  onNoComplianceClick: () => undefined,
  onRepeatOrderClick: () => undefined,
  onViewProductsClick: () => undefined,
  onViewQAClick: () => undefined,
}

export default ActionsCell
