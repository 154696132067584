import styled, { css } from 'styled-components'
import colors from 'Assets/colors'
import { ORDER_STATUS } from 'Config/constants'

export const StateCell = styled.span`
  color: ${({ state }) => (state === ORDER_STATUS.PR || state === ORDER_STATUS.CN) && colors.red};
  ${({ state }) =>
    state === ORDER_STATUS.PR &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`

export const StateCellDisabled = styled.span`
  color: ${() => '#ddd'};
  ${({ state }) =>
    state === ORDER_STATUS.PR &&
    css`
      text-decoration: underline;
    `}
`
