const root = '/'

const mockUsers = '/'

const home = '/home'

const historic = '/historic'

const purchase = '/shopping'

const lists = `${purchase}/lists`

const categories = `${purchase}/categories`

const habitualCats = `${purchase}/habitualCats`

const category = `${categories}/:id`

const habitualCat = `${habitualCats}/:id`

const AddCategory = id => `${categories}/${id}`

const AddCategoryHabitual = id => `${habitualCats}/${id}`

const providers = `${purchase}/providers`

const provider = `${providers}/:providerId`

const addProvider = id => `${providers}/${id}`

const providerProducts = `${provider}/category/:id`

const addCategoryProvider = (providerId, id) => `${providers}/${providerId}/category/${id}`

const productRouteCategory = `${category}/product/:productId`

const productRouteProvider = `${providerProducts}/product/:productId`

const checkout = '/checkout'

const approvedCheckout = '/approver/checkout'

const checkoutConfirmed = '/checkout-confirmed'
const checkoutApprovedConfirmed = '/checkout-approved-confirmed'

const ticketingCreate = '/ticketing/:providerId/:orderId/:documentTypeCode/:documentCompany/new'
const addTicketing = (providerId, orderId, documentTypeCode, documentCompany) =>
  `/ticketing/${providerId}/${orderId}/${documentTypeCode}/${documentCompany}/new`
const ticketingConfirmed = '/ticketing/:orderId/confirmed'
const addTicketingConfirmed = id => `/ticketing/${id}/confirmed`
const ticketingList = '/ticketing'
const ticketingDetail = '/ticketing/:id'
const addTicketingDetail = id => `/ticketing/${id}`

const delivery = '/delivery/:orderId/:documentTypeCode/:documentCompany'
const deliveryConfirmed = '/delivery/:orderId/:documentTypeCode/:documentCompany/confirmed'
const addDeliveryConfirmed = (orderId, docTypeCode, documentCompany) =>
  `/delivery/${orderId}/${docTypeCode}/${documentCompany}/confirmed`
const deliveryQa = '/delivery/:orderId/:documentTypeCode/:documentCompany/qa'
const addDeliveryQa = id => `/delivery/${id}/qa`

const previusOrder = '/previusOrder'

const approverCarts = '/approver/carts'

const carts = '/carts'

const help = '/help'

const addCartsId = id => `${carts}?id=${id}`

const approverSuccessUpdatedOrder = '/approver/updated-order-success'

const failedRequests = '/failed-requests'

const multicenterDashboard = '/multicenter'
const multicenterOrders = `${multicenterDashboard}/orders`
const multicenterRepeatOrder = `${multicenterDashboard}/repeat-order`
const multicenterTicketing = `${multicenterDashboard}/ticketing`
const multicenterTicketingDetail = `${multicenterDashboard}/ticketing/:id`

const routes = {
  root,
  lists,
  mockUsers,
  home,
  historic,
  purchase,
  categories,
  category,
  AddCategory,
  AddCategoryHabitual,
  previusOrder,
  providers,
  provider,
  addProvider,
  providerProducts,
  addCategoryProvider,
  productRouteCategory,
  productRouteProvider,
  checkout,
  approvedCheckout,
  checkoutConfirmed,
  checkoutApprovedConfirmed,
  delivery,
  deliveryConfirmed,
  addDeliveryConfirmed,
  deliveryQa,
  addDeliveryQa,
  ticketingCreate,
  addTicketing,
  ticketingConfirmed,
  addTicketingConfirmed,
  ticketingList,
  ticketingDetail,
  addTicketingDetail,
  habitualCats,
  habitualCat,
  approverCarts,
  carts,
  help,
  approverSuccessUpdatedOrder,
  addCartsId,
  failedRequests,
  multicenterDashboard,
  multicenterOrders,
  multicenterRepeatOrder,
  multicenterTicketing,
  multicenterTicketingDetail,
}

export default routes
