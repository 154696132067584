import React from 'react'

import PropTypes from 'prop-types'

import { Row, TutorialContainer, TutorialText, ButtonContainer, ActionButton, ButtonLiteral } from './styled'

const HelpCard = ({ text, buttonText, onClick }) => (
  <Row buttonText={buttonText}>
    <TutorialContainer>
      <TutorialText>{text}</TutorialText>
    </TutorialContainer>
    <ButtonContainer>
      <ActionButton onClick={onClick}>
        <ButtonLiteral>{buttonText}</ButtonLiteral>
      </ActionButton>
    </ButtonContainer>
  </Row>
)

HelpCard.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default HelpCard
