import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { HelpCard } from 'Components/atoms'
import { CartDrawer, SearchBar } from 'Components/organisms'
import {
  HELP_URL_WATCH_PORTAL_DE_COMPRAS,
  HELP_URL_WATCH_MULTICENTER,
  HELP_URL_WATCH_SHOPPING_LISTS,
  HELP_URL_WATCH_APPROVAL_REQUEST,
  HELP_URL_WATCH_ORDER_APPROVAL,
} from 'Config/constants'

import { Container, Title, SubTitle, Header } from './styled'

const Help = () => {
  const { t } = useTranslation()

  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <>
      <SearchBar setShowDrawer={setShowDrawer} />
      <Container $tutorial={false}>
        <Header>
          <Title>{t('helpTitle')}</Title>
        </Header>
        <SubTitle>{t('helpSubtitle')}</SubTitle>
        <HelpCard
          onClick={() => window.open(HELP_URL_WATCH_PORTAL_DE_COMPRAS, '_blank')}
          text={t('watchPortalDeCompras')}
          buttonText={t('watchTutorial')}
        />
        <HelpCard
          onClick={() => window.open(HELP_URL_WATCH_SHOPPING_LISTS, '_blank')}
          text={t('watchShoppingLists')}
          buttonText={t('watchTutorial')}
        />
        <HelpCard
          onClick={() => window.open(HELP_URL_WATCH_MULTICENTER, '_blank')}
          text={t('watchMultiCenterSection')}
          buttonText={t('watchTutorial')}
        />
        <HelpCard
          onClick={() => window.open(HELP_URL_WATCH_APPROVAL_REQUEST, '_blank')}
          text={t('watchApprovalRequest')}
          buttonText={t('watchTutorial')}
        />
        <HelpCard
          onClick={() => window.open(HELP_URL_WATCH_ORDER_APPROVAL, '_blank')}
          text={t('watchOrderApproval')}
          buttonText={t('watchTutorial')}
        />
      </Container>
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </>
  )
}

export default Help
