/* eslint-disable max-len */

import { categoryTypes } from 'Config/constants'

export default {
  translation: {
    deliveryErrorMsg: 'Error en la recepción del pedido {{request}} del proveedor {{ provider }}',
    seeRequest: 'Ver solicitud',
    maintenance: {
      title: 'Estamos haciendo una actualización.',
      message: 'En breve volverá a estar disponible,\nDisculpe las molestias.',
    },
    yes: 'Sí',
    no: 'No',
    generalError: 'Vaya, ha ocurrido un error',
    welcomeTitle: 'Acceder al Portal de Compras',
    insertCenter: 'Introduce el centro en el que quieres ir a comprar',
    center: 'Centro',
    originCenter: 'Centro de origen',
    destinationCenter: 'Centro de destino',
    access: 'Acceder',
    logout: 'Cerrar sesión',
    searchDots: 'Búsqueda...',
    cart: 'Carro',
    selectACenter: 'Elije el centro de destino',
    selectAOriginCenter: 'Elije el centro de origen',
    changeCenter: 'Cambiar centro de destino',
    changeOriginCenter: 'Cambiar centro de origen',
    change: 'Cambiar',
    accessCenterMessage:
      'Busque y seleccione el centro de destino de la lista de centros asignados que tiene para comenzar a realizar la compra.',
    accessOriginCenterMessage:
      'Busque y seleccione el nuevo centro de origen al que se le facturará la compra de la lista de centros asignados.',
    history: 'Historial',
    accessPortal: 'Acceder al portal de compras',
    complaints: 'No conformidades',
    searchPlaceholderCategory: 'Busque cualquier categoría o proveedor',
    searchPlaceholderCart: 'Busque en el carro',
    categories: 'Categorías',
    providers: 'Proveedores',
    habitualCats: 'Habituales',
    errorAddHabitual: 'Ha ocurrido un error al añadirlo como producto habitual',
    errorDeleteHabitual: 'Ha ocurrido un error al eliminarlo como producto habitual',
    confirmDeleteHabitualTitle: '¿Quieres eliminar este producto habitual?',
    confirmDeleteHabitualDesc:
      'Si eliminas este producto ahora puedes a volver a agregarlo como habitual cuando lo necesites',
    deleteHabitualProduct: 'Eliminar producto habitual',
    [categoryTypes.category]: 'Categoría',
    [categoryTypes.subCategory]: 'Subcategoría',
    [categoryTypes.keymap3]: 'Tipo',
    [categoryTypes.provider]: 'Proveedor',
    noAvailableProducts: 'No hay productos disponibles',
    allCategories: 'Todas las categorías',
    min: 'Min',
    multi: 'Multi',
    filterTemperature: 'Filtrar temperatura',
    deployTypes: 'Desplegar todos los tipos',
    contractTypes: 'Contraer tipos',
    arrangeProducts: 'Ordenar productos',
    seeProducts: 'Ver productos',
    filterProviderProducts: 'Filtrar productos por proveedor',
    contractProducts: 'Contraer productos',
    addCart: 'Añadir al carro',
    added: 'Añadido',
    singleProduct: 'producto',
    arrangeProviders: 'Ordenar proveedores',
    arrangeProvidersAlpha: 'Alfabéticamente',
    arrangeProvidersDesc: 'Nombre Z-A',
    arrangeProvidersDelivery: 'Por próxima fecha de entrega',
    emptyCart: 'Vaciar',
    remainingBudget: 'Presupuesto restante este mes',
    rawMaterial: 'Materia prima',
    notRawMaterial: 'No materia prima',
    finishPurchase: 'Hacer compra',
    seeCart: 'Ver Carro',
    warningCart: 'Esta acción eliminará todos los productos del carro actual y no podrá volver a recuperarlos.',
    titleCart: 'Vaciar todo el carro',
    actionEmptyCard: 'Vaciar carro',
    cancel: 'Deshacer acción',
    someProductsNotAvailable: 'Hay productos en el Carro que no están disponibles en la fecha seleccionada',
    notAvailableProducts: 'Productos no disponibles',
    notAvailableProductsMessage: 'Si realizas la compra ahora, estos productos no se incluirán en el pedido.',
    emptyProvider: 'Vaciar productos del proveedor',
    emptyProviderMessage: 'Esta acción eliminará todos los productos del proveedor y no podrá volver a recuperarlos.',
    actionEmptyProvider: 'Vaciar productos',
    cartErrorModalTitle: 'Vaya, ha ocurrido un error en el carro',
    close: 'Cerrar',
    cartErrorModalMessage: 'Algunos productos que añadió recientemente al carro no se han agregado correctamente',
    deleteProductTitle: 'Borrar producto',
    deleteProductMessage: 'Esta acción eliminará el producto del carro y no podrá volver a recuperarlo.',
    deleteProductAction: 'Borrar producto',
    nextDeliveryDate: 'Próxima fecha de entrega el ',
    nextDeliveryDateProvDetail: 'Próxima fecha de entrega',
    nextDeliveryDateProvDetailLiteral: 'La próxima fecha de entrega del proveedor es el ',
    products: 'Productos',
    sum: 'Importe',
    quantity: 'Cantidad',
    deliveryDateSelect: 'Seleccione fecha de entrega',
    deliveryDateExpired: 'Fecha caducada',
    deliveryDateEdit: 'Pulse si desea seleccionar otra fecha',
    free: 'Sin coste adicional',
    relatedCosts: 'Existirán costes asociados',
    specialrelatedCosts: 'Coste de',
    ordinaryDelivery: 'Entrega normal',
    specialDelivery: 'Entrega especial',
    choose: 'Por elegir',
    confirmOrder: 'Confirmar compra',
    completeDeliveryDates: 'Complete las fechas de entrega',
    selectOriginCenter: 'Seleccione un centro de origen',
    requestApproval: 'Solicitar aprobación',
    goToMarket: 'Volver a la compra',
    costMessage: 'El importe de este pedido se descontará del presupuesto del mes de entrega',
    warningMessage:
      'Hay productos bloqueados en la fecha seleccionada de este proveedor que no se incluirán en el pedido',
    productNoAvailable: 'No es posible entregar este producto en la fecha seleccionada ',
    confirmationTitle: 'Tu compra se ha realizado correctamente',
    confirmationTitleSQS: 'Procesando tu compra',
    confirmationSubtitle:
      'Podrá consultar en cualquier momento el estado de la solicitud {{trackingId}} desde el Historial del centro de destino.',
    confirmationSubtitleSQS:
      'Solo te notificaremos en caso de algún error. Podrá consultar en cualquier momento el estado de la solicitud {{trackingId}} desde el Historial del centro de destino.',
    confirmationApprovedTitle: 'Tu solicitud de compra se ha enviado correctamente.',
    confirmationApprovedSubtitle:
      'El aprobador deberá aprobar la compra. Podrás consultar en cualquier momento el estado de la solicitud {{trackingId}} desde el Historial del centro de destino.',
    confirmationMulReqApprovedTitle: 'Solicitudes de compra enviadas correctamente',
    confirmationMulReqApprovedSubtitle:
      'Desde la sección de “solicitudes por aprobar” podrás consultar el estado de las solicitudes:',
    backHome: 'Volver al inicio',
    toHistory: 'Ir al Historial',
    toRequests: 'Ver solicitudes',
    datesUpdated: 'Fechas guardadas',
    invalidERPOriginCenters: 'Centros origen invalidos en ERP',
    noOriginCenterSelected: 'Ningún centro de origen seleccionado',
    emptyCartTitle: 'El carro está vacío',
    emptyCartSubtitle: 'Podrás hacer una compra cuando añadas productos al carro',
    errorCartTitle: 'Vaya, ha habido un error',
    errorCartSubtitle: 'El carro actualmente no está funcionando de manera correcta',
    errorCartRetry: 'Reintentar',
    loading: 'Cargando...',
    orderStatus: {
      PR: 'Por recepcionar',
      RC: 'Recepcionado',
      PE: 'Tramitado',
      CN: 'Cancelado',
      OT: 'Estado no definido',
    },
    filterModalTrigger: 'Filtrar pedidos del Historial',
    filterModalTitle: 'Filtrar Historial de pedidos',
    filterModalSubtitle: 'Utilice los filtros disponibles para afinar los resultados.',
    filterLabelDate: 'Rango de fechas',
    filterLabelState: 'Estado del pedido',
    filterLabelProvider: 'Proveedor',
    filterLabelOrigin: 'Centro de origen',
    filterLabelOrderNumber: 'Número de pedido',
    filterLabelOrderNumberRes: '(8 dígitos)',
    filterLabelRequestNumber: 'Número de solicitud',
    filterButtonClean: 'Limpiar filtros',
    filterButtonSearch: 'Filtrar pedidos',
    filterPlaceholderDate: 'Seleccione un rango de fechas',
    filterPlaceholderState: 'Seleccione estado del pedido',
    filterPlaceholderCenter: 'Busque un centro',
    filterPlaceholderProvider: 'Busque un proveedor',
    filterPlaceholderOrigin: 'Busque un centro de origen',
    filterPlaceholderSelectOrigin: 'Seleccione un centro de origen',
    filterPlaceholderOrderNumber: 'Introduzca un número de pedido',
    filterPlaceholderRequestNumber: 'Introduzca un número de solicitud',
    orderStatusPR: 'Pulse si desea confirmar el pedido recibido',
    orderStatusPRStef: 'No tiene permisos para recepcionar este pedido',
    orderStatusRC: 'Pedido completado',
    orderStatusPE: 'Se entregará en la fecha seleccionada',
    orderStatusCN: 'Pedido cancelado',
    orderStatusOT: 'Estado no definido',
    historyDeliveryDate: 'Entrega elegida para el ',
    order: 'Pedido ',
    repeatOrderLoading: 'La operación puede tardar unos minutos, por favor espere a que se complete.',
    repeatOrder: 'Repetir pedido',
    repeatOrderHelpText: 'Se añadirá al carro actual',
    repeatOrderErrorsTitle: 'Algunos productos no se han podido añadir al carrito:',
    reapeatOrderModalText:
      'Se añadirán al carro actual todos los productos que estén disponibles con las unidades que introdujiste en el pedido.',
    errorPreviusOrderTitle: 'Error',
    errorPreviusOrderSubtitle: 'No se ha podido obtener los productos del pedido ',
    newOrder: 'Nuevo pedido',
    newOrderHelp: 'A partir de esta solicitud',
    downloadOrder: 'Descargar pedido',
    downloadOrderHelpText: 'Exportar Excel',
    openNoConformities: 'Abrir No Conformidad',
    openNoConformitiesHelpText: 'Crear una No Conformidad de este pedido',
    cancelButton: 'Cancelar',
    requestErrorText: 'La solicitud {{orderId}} ha dado un error',
    requestProcesingText: 'La solicitud {{orderId}} está en proceso de ser registrada',
    qaPageTitle: 'Control de calidad del pedido',
    qaPageSubtitle:
      'Este es el registro obligatorio que nos solicita Sanidad en sus inspecciones. Es importante que responda a estas cuestiones del pedido {{orderId}} y confirme si está conforme con lo recibido. Si va a poner una NO CONFORMIDAD deberá indicar aquí el motivo por favor.',
    qaTitleReceptionTime: 'Hora de recepción',
    qaTitleTransport: 'Vehículo / Transportista',
    qaTitlePackage: 'Envase y embalaje',
    qaTitleLifeUse: 'Vida útil',
    qaTitleProductAspect: 'Aspecto del producto',
    qaTitleProductLabel: 'Etiquetado del producto',
    qaTitleAcceptedProduct: 'Producto aceptado',
    qaManualFrozenRefrigerated: 'Quiero añadir la temperatura de congelados o refrigerados',
    agree: 'Conforme',
    disagree: 'Disconforme',
    qaFrozenTemperatureLabel: 'Temperatura congelados',
    qaFrozenTemperatureCommentLabel: '¿Qué producto has testado/comprobado?',
    qaRefrigeratedTemperatureLabel: 'Temperatura refrigerados',
    qaRefrigeratedCommentTemperatureLabel: '¿Qué producto has testado/comprobado?',
    qaSendQA: 'Enviar control de calidad',
    writeComment: 'Escriba un comentario',
    guideNewsInfo: 'El nuevo Portal de Compras se encuentra actualmente en desarrollo. Pulse ',
    guideNewsInfoAnchor: 'aquí',
    guideNewsInfoPostText: ' para ver una guía con todas las novedades.',
    orderRecievedTitle: 'Tu pedido se ha recepcionado correctamente',
    orderRecievedTitleSQS: 'Procesando recepción de pedido',
    orderRecievedSubtitle:
      'Su estado en el Historial pasará a Recepcionado y, si ha tenido algún problema con el proveedor, podrá abrir una No conformidad.',
    orderRecievedSubtitleSQS: 'Solo te notificaremos en caso de algún error.',
    orderRecievedSubtitleMulticenter:
      'Su estado en la sección de Pedidos pasará a Recepcionado y, si ha tenido algún problema con el proveedor, podrá abrir una No conformidad.',
    openNoCompliance: 'Abrir una No conformidad',
    backToHistory: 'Volver al Historial',
    fillOutDTA: 'Cumplimentar DTA-010 si hay No conformidad',
    receptionOrderTitle: 'Recepción pedido',
    receptionOrderSubtitle:
      'Comprueba el precio y la cantidad recibida de todos los productos para actualizar el importe total del pedido.',
    request: 'Solicitud {{request}}',
    status: 'Estado',
    product: 'Producto',
    orderQty: 'Cantidad pedida',
    recievedQty: 'Cantidad recibida',
    price: 'Precio',
    receptionDate: 'Fecha de recepción',
    invalidSelectDate: 'La fecha no debería ser anterior a la indicada en rojo',
    select: 'Seleccionar',
    fillAllOut: 'Complete todos los campos',
    fillNoteNumber: 'Introducir número de albarán',
    toBeFilled: 'Por introducir',
    toBeReceptionedNotificationLiteral: 'Hay pedidos que deben ser recepcionados',
    viewPROrders: 'Ver pedidos por recepcionar',
    petitionSuccess: 'Petición completada',
    petitionError: 'Ha habido un error en la petición',
    notAvailable: 'No disponible',
    mandatoryField: 'Campo obligatorio',
    errorLoadingOrders: 'Ha ocurrido un problema con los criterios de búsqueda introducidos',
    emptyResults: 'No se han encontrado pedidos con tus criterios de búsqueda',
    confirmOrderReception: 'Confirmar recepción del pedido',
    frozen: 'Congelado',
    refrigerated: 'Refrigerado',
    room: 'Ambiente',
    any: 'Cualquiera',
    affectedAmountLabel: 'Cantidad afectada',
    ticketingPageTitle: 'No Conformidad en el pedido {{orderId}}',
    ticketingPageSubtitle:
      'Indique el tipo de No Conformidad, introduzca una descripción que explique el problema y suba los archivos adjuntos que sean necesarios.',
    ticketingDescriptionLabel: 'Descripción de la No Conformidad',
    ticketingDescriptionPlaceholder: 'Describa brevemente la No Conformidad',
    ticketingConfirm: 'Confirmar No Conformidad',
    ticketingRedeliveryLabel: 'Necesita reentrega',
    ticketingRedeliveryPlaceholder: 'Seleccione un tipo',
    ticketingStateLabel: 'Tipo de No Conformidad',
    ticketingStatePlaceholder: 'Seleccione un tipo',
    ticketingFilesLabel: 'Subida archivos No Conformidad',
    ticketingFilesLabelInfoTitle: 'Al seleccionar una No Conformidad de ese tipo, necesitaremos para tramitarla:',
    ticketingFilesLabelInfoRequiredDeliveryNote: 'Una foto del albarán.',
    ticketingFilesLabelInfoRequiredPhoto: 'Una foto del producto.',
    ticketingFilesLabelInfoRequiredDeliveryNoteAndPhoto: 'Una foto del albarán y una foto del producto',
    ticketingFilesDropzone: 'Seleccione para buscar los ficheros o arrástrelos aquí para subirlos',
    ticketingSubmitError: 'Vaya, ha ocurrido un error',
    ticketingUploadFileError: 'Ha ocurrido un error al subir el fichero',
    ticketingUploadFileExtensionError: 'Formato no permitido',
    ticketingUploadFileSizeError: 'No se pueden subir archivos de más de 10mb',
    ticketingRecievedTitle: 'Tu No Conformidad se ha enviado correctamente',
    ticketingRecievedSubtitle:
      'Recibirá en los próximos minutos un correo electrónico y podrá consultar desde ahí el estado y las nuevas actualizaciones.',
    ticketingCompleteTitle: 'Complete la No Conformidad',
    ticketingCompleteSubtitle:
      'Es necesario para procesar la No Conformidad que seleccione los productos que se hayan visto afectados e incluya el lote al que pertenecen y la fecha de caducidad.',
    ticketingTableHeaderSelec: 'Selec.',
    ticketingTableHeaderLot: 'Lote',
    ticketingTableHeaderExpDate: 'Fecha de caducidad',
    ticketingTableHeaderExpDateAbrev: 'Fecha de cad.',
    ticketingActionInfoTitle: 'Tipo de No Conformidad seleccionada',
    ticketingActionSend: 'Enviar No Conformidad',
    ticketingActionDisabled: 'Complete todos los campos',
    ticketingNumberOfProducts: ' {{numberOfProducts}} productos',
    ticketingNumberOfProduct: ' {{numberOfProducts}} producto',
    ticketingListTitle: 'No Conformidades',
    ticketingListSubtitle:
      'Para abrir una No Conformidad, deberá hacerlo desde el Historial seleccionando el pedido que haya tenido una incidencia tras su recepción.',
    ticketingNoResponseNotificationCopy: 'Hay No Conformidades que deben ser respondidas',
    ticketingNoResponseNotificationButton: 'Ver No Conformidades pendientes de responder',
    ticketingFilterStatus: 'Filtrar estado',
    ticketingStatus: {
      new: 'Enviada',
      pending: 'Necesita respuesta',
      open: 'En curso',
      hold: 'En curso',
      solved: 'Resuelto',
      closed: 'Cerrado',
    },
    ticketingErrors: {
      addComment: 'Error intentando añadir comentario.',
      fetchTicket: 'Error al intentar recuperar el ticket',
    },
    lists: 'Listas',
    shoppingList: {
      createShoppingList: 'Crear lista de compra',
      create: {
        title: 'Crea lista de compra',
        label: 'Nombre de la lista',
        placeholder: 'Ejemplo: Frutas de verano',
        info:
          'Esta lista aparecerá en el apartado de listas dentro del menu. Podrás editarla o eliminarla en cualquier momento.',
        submit: 'Crear lista',
        errorDuplicated: 'Ya existe una lista con este nombre.',
        success: 'Se ha creado la lista de la compra',
      },
      list: {
        title: 'Listas de compra',
        createdAt: 'Actualizada el {{date}}',
        expiresAt: 'Caduca el {{date}}',
        removeList: 'Eliminar lista',
        seeList: 'Ver lista',
      },
      remove: {
        title: 'Estas a punto de eliminar una lista de compra',
        confirm: 'Eliminar lista',
        cancel: 'Volver a la lista',
        success: 'La lista ha sido eliminada',
      },
      removeProduct: {
        title: '¿Quieres eliminar este producto de la lista?',
        confirm: 'Eliminar producto',
        cancel: 'Volver a la lista',
        success: 'El producto ha sido eliminado',
      },
      single: {
        back: 'Volver a listas de compra',
        title: 'Listas de compra - {{name}}',
        change: 'Cambiar nombre',
        remove: 'El producto ha sido eliminado',
      },
      edit: {
        title: 'Editar lista de compra',
        label: 'Nombre de la lista',
        placeholder: 'Ejemplo: Frutas de verano',
        info:
          'Esta lista aparece en el apartado de listas dentro del menu. Podrás editarla o eliminarla en cualquier momento.',
        submit: 'Editar lista',
        errorDuplicated: 'Ya existe una lista con este nombre.',
        success: 'Se ha modificado la lista de la compra',
      },
      add: {
        success: 'Se han añadido al carro los productos de la lista de la compra',
        confirm: 'El carrito ya contiene artículos. Estos productos se sumarán a los ya existentes en el carrito.',
      },
      addToList: {
        modalTitle: 'Añade el producto a una lista de la compra',
        btnTitle: 'Añadir a lista',
        select: 'Seleccionar lista',
        success: 'Se ha añadido a la lista seleccionada',
        error: 'Este producto ya se encuentra agregado a esta lista',
        noResults: 'Aún no tienes listas de la compra',
      },
      errorMessages: {
        fetchLists: 'Error recuperando las listas de la compra',
        removeList: 'Error al eliminar la lista de la compra',
        fetchListsProducts: 'Error al intentar recuperar los productos',
        removeProduct: 'Error al intentar eliminar el producto de la lista',
        sendToCart: 'Error al intentar enviar la lista al carrito',
        updateProduct: 'Error al intentar actualizar los productos de la lista',
        addProduct: 'Error al intentar añadir el producto a la lista de la compra',
      },
      lists: {
        noResults: 'Actualmente no hay listas de la compra creadas',
      },
    },
    singleTicketHeaderId: 'No Conformidad {{noConformityId}}',
    singleTicketHeaderDate: 'Solicitado {{noConformityDate}}',
    singleTicketReceptionedDate: 'Recepcionado {{orderReceptionedDate}}',
    singleTicketUpdatedDate: 'Actualizado {{ticketUpdatedDate}}',
    singleTicketStatusAux: 'Estado de la No Conformidad',
    singleTicketDetailHeaderDate: 'Completar con el dato que corresponda {{noConformityDate}}',
    singleTicketInputCommentPlaceholder: 'Escribe tu mensaje',
    receptionStatusTextShort: {
      ST: 'SUST',
      DF: 'DEFEC',
      OK: 'OK',
      EX: 'EXCES',
      FT: 'FALTA',
    },
    receptionStatusText: {
      ST: 'Sustituido',
      DF: 'Por defecto',
      OK: 'OK',
      EX: 'Por exceso',
      FT: 'Falta',
    },
    loadingBody:
      'El proceso puede llegar a tardar unos minutos mientras registramos y enviamos los datos. Por favor, no salga de esta pestaña.',
    loadingTitle: 'Estamos tramitando la información',
    announcementsTitle: 'Noticias',
    announcementsNoData: 'No hay noticias',
    announcementsSeeAll: 'Ver todas las Noticias',
    lastItems: 'Últimas unidades',
    noItems: 'Agotado',
    nextAvailability: 'Próximamente',
    new: 'Nuevo',
    deleted: 'Baja',
    substitute: 'Sustituto',
    delete: 'Borrar',
    noProductsTitle: 'Lo sentimos, aquí no hay nada',
    noProductsLiteral: 'No tenemos productos para mostrarle en esta página. Puede seguir navegando en otras secciones.',
    enterTitle: 'Bienvenido al Portal de Compras',
    enterLoginMessage: 'Puedes iniciar sesión con tu usuario y contraseña o a través de tu cuenta de Microsoft',
    enterLoginMessageMsal: 'Para acceder al portal inicia sesión con tu cuenta corporativa',
    enterPortal: 'Acceder con usuario',
    enterAzurePortal: 'Acceder con Microsoft',
    loginUserLabel: 'Usuario o correo electrónico',
    loginPassLabel: 'Contraseña',
    loginUserPlaceholder: 'Introduzca aquí su usuario o su correo electrónico',
    loginPassPlaceholder: 'Introduzca aquí su contraseña',
    errorUsername: 'Debe ingresar un usuario o un correo electrónico válido',
    errorPassword: 'Debe ingresar la contraseña',
    errorLogin: 'Se ha producido un error en el Login',
    errorCredentials: 'El usuario o la contraseña no son válidos',
    errorTitle: 'ERROR',
    noCalendar: 'Sin fecha de entrega disponible',
    smallDevice:
      'Aplicación optimizada para su visualización horizontal. Por favor, gire su tablet o amplíe la ventana.',
    smallDeviceHeader: 'Ups, no estamos preparados para este dispositivo',
    smallDeviceSubHeader: 'Para continuar en el Portal de Compras descárgate la App desde el siguiente enlace',
    priceUnit0error: 'El valor no puede ser 0',
    moreInfoPrice: 'MÁS INFO SOBRE PRECIO',
    moreInfoDescription1: 'A partir del ',
    moreInfoDescription2: ' el precio cambia a ',
    checkoutChangeDateLoading: 'Esta operación puede durar unos instantes',
    deliveryErrorTitle: 'Vaya, ha habido un error',
    deliveryError:
      'La recepción de pedido ha dado error, por favor, inténtalo de nuevo más tarde o ponte en contacto con el departamento de sistemas',
    validationDeliveryError:
      'Hay errores de validación, revise sus lineas de producto. Recuerde que no puede haber ningún precio de producto a 0€.',
    total: 'Importe',
    totalLine: 'Importe',
    returnPrevius: 'Volver a',
    availability: 'Disponibilidad en catálogo',
    availabilityUntil: 'El producto estará disponible en el catálogo hasta el',
    seeQA: 'Ver control de calidad',
    downloadQA: 'Descargar control de calidad',
    qaTitleOrder: 'Pedido',
    qaTitleQAcontrol: 'Control de calidad',
    qaTemp: 'Temperatura refrigerados',
    qaTempComments: '¿Qué producto has testado/comprobado?',
    qaTempFreeze: 'Temperatura congelados',
    qaTempCommentsFreeze: '¿Qué producto has testado/comprobado?s',
    newRequest: 'Nueva solicitud ',
    newrequestFrom: 'a partir del pedido ',
    selectRequest: 'Seleccione los productos que desee añadir al carro',
    continueFromLastRequest: 'Continúa añadiendo productos desde el último pedido abierto',
    gotoRequest: 'Ir al pedido',
    downloadOrders: 'Descargar pedidos',
    downloadOrdersQa: 'Descargar control de calidad',
    requestsToBeApproved: 'Solicitudes por aprobar',
    approvedRequests: 'Solicitudes aprobadas',
    rejectedRequests: 'Solicitudes rechazadas',
    cancelledRequests: 'Solicitudes canceladas',
    weekSummary: 'Resumen semanal',
    newFunctionality: 'Nueva funcionalidad: ',
    monitorRequest: '"Aprobar solicitudes" ',
    nowApprovalApproverRequests: 'para aprobar los pedidos de tus centros. ',
    nowApprovedRequest: 'para solicitar aprobación de tus pedidos. ',
    howItWorks: 'Ver cómo funciona',
    orderRequests: 'Solicitudes de pedidos',
    noCartsToBeApprove: 'Hola, ahora no tienes carritos para aprobar',
    continueWatching: 'Puedes seguir navegando en otras secciones de tu portal de compras.',
    selectYourCenter: 'Selecciona tu centro',
    haveCartRequests: '¡Tienes solicitudes de carritos de compra pendientes por aprobar!',
    approveCartRequests: 'Las solicitudes de carritos de compra se aprobarán automáticamente dentro de 24h.',
    seeCartsToBeApprove: 'Ver carritos por aprobar',
    cartsCloseExpired: 'Carritos próximos a caducar',
    cartsPendingApproval: 'Carritos pendientes de aprobación',
    cartsApproved: 'Carritos aprobados',
    cartsRejected: 'Carritos rechazados',
    cartsCancelled: 'Carritos aprobados',
    seeCart2: 'Ver carrito',
    deliveryDateApprover: 'Entrega {{deliveryDate}}',
    requestIdApprover: 'Código {{requestId}}',
    limitDateApprover:
      'Tienes hasta el día {{limitDate}} para aprobar esta solicitud, pasado esta fecha el carrito se aprobará automaticamente.',
    amount: 'Importe:',
    noCartsRejected: 'Hola, ahora no tienes carritos rechazados',
    noCartsApproved: 'Hola, ahora no tienes carritos aprobados',
    noCartsCancelled: 'Hola, ahora no tienes carritos cancelados',
    requestApproved: 'Solicitud aprobada por {{statusUpdatedBy}}',
    approvedDate: 'Fecha de aprobación {{approvedDate}}',
    requestRejected: 'Solicitud rechazada por {{statusUpdatedBy}}',
    rejectedDate: 'Fecha de rechazo {{rejectedDate}}',
    requestDate: 'Fecha de solicitud {{requestDate}}',
    cancelledDate: 'Fecha de cancelación {{cancelledDate}}',
    requestCancelled: 'Solicitud cancelada',
    numberOfProducts: ' productos',
    numberOfProduct: ' producto',
    requests: 'Solicitudes',
    cartPending: 'Pendiente de aprobación',
    cancelRequest: 'Cancelar solicitud',
    userApprovedAuto: 'Aprobado automáticamente',
    userApproved: 'Aprobado por: {{user}}',
    userRejected: 'Rechazado por {{user}}',
    userCancelled: 'Cancelado por {{user}}',
    aproveCart: 'Aprobar Carrito',
    updateRequestSuccess: 'Solicitud aprobada con éxito',
    backToRequests: 'Volver a las solicitudes',
    rejectCart: 'Rechazar Carrito',
    rejectRequestSuccess: 'Solicitud rechazada con éxito',
    rejectCartTitle: 'Estas a punto de rechazar un carrito',
    rejectCartAction: 'Rechazar Carrito',
    rejectCartCancel: 'Volver al carrito',
    addProducts: 'Añadir productos',
    approvedInfo: 'Estás añadiendo nuevos productos a la solicitud nº {{orderId}}',
    backToRequest: 'Volver a la solicitud',
    approveRequests: 'Aprobar solicitudes',
    filterCenter: 'Filtrar solicitud por centro',
    approveRequest: 'Aprobar solicitudes',
    noResults: 'Sin resultados',
    searchProvider: 'Buscar por proveedor',
    searchRequest: 'Buscar por número de solicitud',
    noResultsPlaceholder: 'Escribe al menos una letra para buscar resultados',
    createdBy: 'Creado por {{user}}',
    requestCancelledSuccessInfo: 'Solicitud {{id}} cancelada por {{username}}',
    requestOrderStatus: {
      'Pte Aprobacion': 'Pendiente de aprobación',
      Cancelada: 'Solicitud Cancelada',
      Aprobada: 'Solicitud Aprobada',
      Rechazada: 'Solicitud Rechazada',
    },
    requestPendingInfo: 'Puedes revisar el pedido que tienes pendiente de aprobación.',
    requestedAmount: 'Cantidad solicitada',
    approvedAmount: 'Cantidad Aprobada',
    approvalState: {
      NO_CHANGES: '',
      MODIFIED: 'Modificado',
      DELETED: 'Eliminado',
      ADDED: 'Añadido',
    },
    modified: 'Con modificación',
    noModified: 'Sin modificación',
    isAutomaticallyApproved: 'Automaticamente',
    userInvalidErrorMessage: 'El usuario {{username}} no tiene habilitado el acceso al portal de compras',
    userNoCentersErrorMessage: 'El usuario {{username}} no tiene centros asociados',
    failedRequests: {
      processAgain: 'Tramitar de nuevo',
      processAgainHelpText: 'Se añadirá al carro actual',
      removeRequest: 'Borrar Solicitud',
      removeRequestTitleModal: 'Eliminar solicitud',
      removeRequestMessageModal:
        'Esta acción eliminará esta solicitud en estado de error y no podrá volver a recuperarla.',
      removeRequestConfirmBtn: 'Eliminar solicitud',
      removeRequestSuccess: 'Se ha eliminado la solicitud con error',
      removeRequestFailed: 'Ha ocurrido un error eliminando la solicitud',
      removeRequestHelpText: 'No podras recuperar los productos',
      requestStatusError: 'Pedidos con error',
      requestStatusError1: 'Pedido con error',
      requestStatusError2: 'Contacta con el equipo de Supply Chain',
      requestStatusError3: 'Pedido con error, no se ha enviado al proveedor. Contacta con Supply Chain',
      requestStatusErrorCorrected: 'Solicitud con error',
      titleSection: 'Solicitudes con error',
      addRequestToCartTitleModal: 'Añadir productos de la solicitud',
      addRequestToCartMessage: 'Vas a añadir los productos de una solicitud con error al carrito',
      addRequestToCartConfirmBtn: 'Añadir al carrito',
      addRequestToCartError: 'Error añadiendo la solicitud con error al carrito',
      removeRequestCheckoutModalTitle: 'Borrar solicitud {{id}}',
      removeRequestCheckoutModalMessage:
        'has creado una solicitud nueva a partir de una solicitud con error. ¿quieres eliminar la solicitud {{id}} ?"',
      removeRequestCheckoutModalConfirmBtn: 'Eliminar solicitud',
      removeRequestCheckoutModalNotNow: 'Ahora no',
      requestFailedAdviceCartTooltip: 'El carrito actual ya tiene asociada una solicitud con error',
      titleDeliverySection: 'Recepciones con error',
      deliveryErrorItemHeader: 'Error en la recepción del pedido {{ id }}',
      deliveryErrorItemInfo:
        'La recepción del pedido {{id}} no se ha podido procesar, por favor ponte en contacto con el equipo de Supply Chain.',
    },
    errorFetchingCenters: 'Error al intentar cargar los centros',
    purchasesErrorMessages: {
      fetchProducts: 'Error al intentar cargar los productos. ({{ apiMessage }})',
      fetchCategories: 'Error al intentar cargar las categorías. ({{ apiMessage }})',
      fetchProviders: 'Error al intentar cargar los proveedores. ({{ apiMessage }})',
      getCartSagas: 'Error al intentar cargar el carrito. ({{ apiMessage }})',
      updateCartSaga: 'Error al actualizar el carrito. ({{ apiMessage }})',
      fetchOrders: 'Error al intentar cargar los pedidos. ({{ apiMessage }})',
      fetchProviderOrders: 'Error al intentar cargar los pedidos. ({{ apiMessage }})',
      fetchPendingProviderOrders: 'Error al intentar cargar los pedidos pendientes de recepcionar. ({{ apiMessage }})',
      fetchCategoriesHabitual: 'Error al intentar cargar las categorías habituales. ({{ apiMessage }})',
      fetchHabitualProducts: 'Error al intentar cargar los productos habituales. ({{ apiMessage }})',
    },
    cartsErrorMessages: {
      fetchApproved: 'Error al intentar cargar las solicitudes aprobadas. ({{ apiMessage }})',
      fetchCancelled: 'Error al intentar cargar las solicitudes canceladas. ({{ apiMessage }})',
      fetchRejected: 'Error al intentar cargar las solicitudes rechazadas. ({{ apiMessage }})',
      fetchPendingApproval: 'Error al intentar cargar las solicitudes por aprobar. ({{ apiMessage }})',
      fetchCart: 'Error al intentar cargar los productos de la solicitud. ({{ apiMessage }})',
    },
    centerModal: {
      centerWithoutProviders:
        'El centro seleccionado no tiene proveedores asignados, por favor contacta con Supply Chain.',
    },
    approverTutorial: {
      stepOne: {
        title: 'Nueva funcionalidad: Supervisar Solicitudes',
        text:
          'Ahora debes supervisar las solicitudes de pedidos de tu centro. Aprueba, rechaza o edita los pedidos de tus centro.',
        secondaryText:
          'A continuación te explicamos el proceso con datos de ejemplo. Podrás salir al terminar todos los pasos.',
      },
      stepTwo: {
        title: 'Solicitudes por aprobar',
        text:
          'Aquí encontrarás todas las solicitudes que debes supervisar y aprobar. Si no las apruebas dentro del plazo, la solicitud se procesará automáticamente.',
        target: 'Solicitudes por aprobar',
      },
      stepThree: {
        title: 'Buscar solicitudes',
        text:
          'Desde esta barra podrás buscar o filtrar solicitudes por proveedores, por centro o por número de solicitud, si tienes más de uno asignado.',
        target: 'Filtros de solicitudes',
      },
      stepFour: {
        title: 'Listado de solicitudes',
        text:
          'Aquí tienes todas las solicitudes, ordenadas por las más próximas a caducar. Si no las apruebas se procesarán automáticamente. (Datos de ejemplo)',
        target: 'Listado de solicitudes por aprobar',
      },
      stepFive: {
        title: 'Visualizar el carrito',
        text:
          'Debes hacer clic en “ver carrito” para visualizar el detalle de la solicitud y poder aprobar, editar o rechazar la solicitud.',
        target: 'Ver carrito',
      },
      stepSix: {
        title: 'Editar producto',
        text: 'Una vez en el carrito, podrás ver, editar o eliminar los productos y cantidades solicitadas.',
        target: 'Producto del carrito',
      },
      stepSeven: {
        title: 'Rechazar solicitud',
        text: 'Si no estás conforme con el carrito, desde esta opción puedes rechazarlo.',
        target: 'Rechazar carrito',
      },
      stepEight: {
        title: 'Añadir productos al carrito',
        text: 'Si necesitas añadir nuevos productos, desde esta opción podrás añadirlos desde el Portal de Compras.',
        target: 'Añadir al carrito',
      },
      stepNine: {
        title: 'Añadir productos al carrito',
        text: 'Busca, filtra y añade cualquier producto. Una vez termines, vuelve a la solicitud por aprobar.',
      },
      stepTen: {
        title: 'Volver a la solicitud',
        text: 'Una vez que añadas los productos, vuelve a la solicitud desde aquí.',
        target: 'Volver a la solicitud',
      },
      stepEleven: {
        title: 'Aprobar solicitud',
        text: 'Una vez que estés conforme con el carrito, puedes aprobarlo.',
        target: 'Aprobar carrito',
      },
      stepTwelve: {
        title: 'Solicitudes Aprobadas',
        text: 'Aquí encontrarás todas las solicitudes que ya han sido aprobadas.',
        target: 'Solicitudes aprobadas',
      },
      stepThirteen: {
        title: 'Solicitudes Rechazadas',
        text: 'Aquí encontrarás todas las solicitudes que han sido rechazadas.',
        target: 'Solicitudes rechazadas',
      },
    },
    priceChangedDate: 'Fecha del cambio de precio',
    requestTutorial: {
      stepOne: {
        title: 'Nueva funcionalidad: Solicitar pedido',
        text: 'Ahora debes solicitar la aprobación de un pedido antes de procesar la compra.',
        secondaryText:
          'A continuación te explicamos el proceso con datos de ejemplo. Podrás salir al terminar todos los pasos.',
      },
      stepTwo: {
        title: 'Solicitar aprobación',
        text: 'Una vez que tengas tu carrito listo, puedes solicitar la aprobación.',
        target: 'request-tutorial-step-2',
      },
      stepThree: {
        title: 'Confirmación de solicitud',
        text:
          'Una vez hecha la solicitud, verás un mensaje de confirmación, donde podrás volver al inicio o a tus solicitudes.',
        target: 'request-tutorial-step-3',
      },
      stepFour: {
        title: 'Solicitudes',
        text:
          'Desde el menú de navegación, accede a la nueva sección de solicitudes para que puedas ver el estado de todas tus solicitudes.',
        target: 'request-tutorial-step-4',
      },
      stepFive: {
        title: 'Solicitudes por aprobar',
        text:
          'Aquí encontrarás todas las solicitudes pendientes de aprobar. Si tu aprobador no las aprueba dentro del plazo, el pedido se procesará automáticamente.',
        target: 'request-cart-requestsToBeApproved',
      },
      stepSix: {
        title: 'Listado de solicitudes',
        text:
          'Aquí tienes todas las solicitudes pendientes de aprobar, ordenadas por las más próximas a caducar. Si tu aprobador no las aprueba dentro del plazo, el pedido se procesará automáticamente.',
        target: 'request-tutorial-carts-list',
      },
      stepSeven: {
        title: 'Cancelar solicitud',
        text: 'Podrás cancelar la solicitud desde la vista previa haciendo clic aquí.',
        target: 'request-tutorial-step-7',
      },
      stepEight: {
        title: 'Ver productos',
        text: 'En cualquier momento podrás ver tu carrito con todos los productos haciendo clic aquí.',
        target: 'request-tutorial-step-8',
      },
      stepNine: {
        title: 'Detalle del carrito',
        text: 'Podrás ver el pedido con todos los productos que tienes pendientes de aprobar.',
        target: 'request-tutorial-step-9',
      },
      stepTen: {
        title: 'Solicitudes Aprobadas',
        text: 'Aquí encontrarás todas las solicitudes que han sido aprobadas.',
        target: 'request-cart-approvedRequests',
      },
      stepEleven: {
        title: 'Listado de solicitudes',
        text: 'Aquí encontrarás todas las solicitudes que ya han sido aprobadas.',
        target: 'request-tutorial-carts-list',
      },
      stepTwelve: {
        title: 'Detalle de carrito',
        text:
          'Podrás ver el pedido aprobado con el detalle de cada uno de los productos solicitados. Y además podrás ver si un producto ha sido modificado, eliminado o añadido.',
        target: 'request-tutorial-step-13',
      },
      stepThirteen: {
        title: 'Solicitudes Rechazadas',
        text: 'Aquí encontrarás todas las solicitudes que han sido rechazadas.',
        target: 'request-cart-rejectedRequests',
      },
      stepFourteen: {
        title: 'Solicitudes Canceladas',
        text: 'Aquí encontrarás todas las solicitudes que has iniciado y posteriormente has cancelado.',
        target: 'request-cart-cancelledRequests',
      },
    },
    tutorialError: 'No se ha podido iniciar el tutorial',
    tutorial: {
      initTutorial: 'Iniciar tutorial',
      back: 'Anterior',
      nextTutorial: 'Siguiente',
      finishTutorial: 'Finalizar',
    },
    ieBrowserHeader: 'Ups, no estamos preparados para este navegador',
    ieBrowserSubHeader:
      'Accede al portal de compra desde otro navegador. Te recomendamos Chrome, Microsoft Edge o  Firefox.',
    multicenterWelcomeMessage: 'Hola {{username}}, aquí tienes toda la información sobre tus centros.',
    downloadAllOrders: 'Descargar todos los pedidos',
    centerCode: 'Código del centro:',
    viewOrders: 'Ver pedidos',
    hideOrders: 'Ocultar pedidos',
    noCompliance: 'No conformidad',
    provider: 'Proveedor',
    deliveryDate: 'Fecha entrega',
    requestOrder: 'Solicitud de pedido',
    actions: 'Acciones',
    noData: 'No se han encontrado datos',
    ordersPerPage: 'Pedidos por página:',
    numOfNum: '{{num1}} de {{num2}}',
    backToCenter: 'Volver a un centro',
    myCenters: 'Mis Centros',
    orders: 'Pedidos',
    approval: 'Aprobación',
    helpSection: 'Help',
    orderProducts: 'Productos del pedido Nº',
    downloadProductCatalog: 'Descargar catálogo de productos',
    downloadProductCatalogTitle: 'Descarga de catálogo de productos en curso',
    downloadProductCatalogDescription: 'Una vez se termine la descarga te llegará el catálogo a tu email',
    accept: 'Aceptar',
    downloadCatalogError: 'No se ha podido descargar el catálogo',
    addMoreProducts: 'Añadir más productos',
    backToMyCenters: 'Volver a "Mis centros"',
    backToMyOrders: 'Volver a pedidos',
    downloadMulticenterTitle: 'Selecciona qué quieres descargar',
    downloadMulticenterDescription: 'Las descargas serán según lo que tengas filtrado',
    downloadOrdersList: 'Descargar lista de pedidos',
    download: 'Descargar',
    downloadCatalog: 'Descargar catálogo de productos',
    filterMulticenterModalTitle: 'Filtrar pedidos de todos tus centros',
    filterMulticenterModalSubtitle: 'Utilice los filtros disponibles para afinar los resultados.',
    filteredSearchResults: 'Estos son los resultados de tu búsqueda',
    back: 'Volver',
    viewDetail: 'Ver detalle',
    filterByCenter: 'Filtrar por centro',
    noCompliancePerPage: 'No Conformidades por página:',
    filterToDownload: 'Debes aplicar un filtro para descargar los pedidos',
    number: 'Número',
    type: 'Tipo',
    date: 'Fecha',
    noMulticenterResults: 'Lo sentimos, aquí no hay nada',
    noMulticenterResultsSub: 'No hemos encontrado resultados con los filtros seleccionados',
    assignedCenters: 'Centros asignados',
    totalOrdersInProgress: 'Pedidos totales en curso',
    ordersToReceive: 'Pedidos por recepcionar',
    noSearchTitle: 'Lo sentimos, aquí no hay nada',
    noRequestSearchTitles: 'No existen solicitudes con los filtros seleccionados',
    updatedCart: 'Carrito actualizado a la última versión',
    deliveryQaConfirmedError: 'El pedido {{id}} ya ha sido recepcionado',
    approvedRequestException: 'El carrito ya ha sido aprobado',
    downloadOrdersExcelTitle: 'Descarga de pedido en curso',
    downloadOrdersExcelDescription: 'Una vez se termine la descarga te llegará el archivo a tu email',
    downloadOrdersExcelMultipleTitle: 'Descarga de pedidos en curso',
    downloadOrdersExcelMultipleDescription: 'Una vez se termine la descarga te llegará el archivo a tu email',
    downloadQaExcelTitle: 'Descarga de control de calidad en curso',
    downloadQaExcelDescription: 'Una vez se termine la descarga te llegará el archivo a tu email',
    downloadQaExcelMultipleTitle: 'Descarga de controles de calidad en curso',
    downloadQaExcelMultipleDescription: 'Una vez se termine la descarga te llegará el archivo a tu email',
    totalByWeek: 'Importe del proveedor por semana: ',
    seeOrder: 'Ver solicitudes',
    hideOrder: 'Ocultar solicitudes',
    summary: {
      request: 'Solicitud Nº',
      total: 'Importe: ',
      createdDate: 'Fecha solicitud:',
      state: 'Estado: ',
      approve: 'Aprobar',
      cancel: 'Rechazar',
      see: 'Ver carrito',
      week: 'Semana',
      totalUsed: 'Presupuesto utilizado',
      deliveryDate: 'Entrega elegida para el ',
      selectCenter: 'Selecciona un centro',
      search: 'Busca y selecciona el centro en el que quieres ver el resumen semanal.',
      summaryTotal: 'Gasto total de la semana',
      noResults: 'No hay resumen para la semana',
    },
    reloadingProducts: 'Se están cargando todos los productos. Por favor, reintente nuevamente en unos instantes',
    showRequestsWithError: 'Pedidos con error',
    showReceptionError: 'Recepciones con error',
    showPR: 'Pedidos por recepcionar',
    notices: 'Avisos',
    downloadOrderList: 'Lista de pedidos',
    downloadQAList: 'Control de calidad',
    downloadCatList: 'Catálogo de productos',
    downloadAdmin: 'Gestión de descargas',
    selectDownload: 'Selecciona lo que quieres descargar',
    dateRange: 'El rango de fecha no debe ser superior a 31 días ',
    fiveCenterseRange: 'Selecciona hasta 5 centros',
    centers: 'Centros',
    range: 'Rango de fecha ',
    datesRequired: 'Debe indicar el rango de fechas',
    moreProducts: 'Ver más productos',
    filterMulticenterQATitle: 'Filtrar control de calidad',
    notAvailableDate: 'Estos productos no están disponibles para la fecha seleccionada',
    nextDate: 'Próximamente el ',
    someProductsNotAvailableCart:
      '<strong>Para la próxima fecha de entrega, </strong>algunos productos no estarán disponibles.',
    oredrNotReceivedNC:
      'Al registrar esta no conformidad, la recepción del pedido quedará pausada hasta que el equipo de Supply finalice su revisión y te indique los pasos a seguir.',
    helpTitle: 'Ayuda',
    helpSubtitle: 'Aquí podrás acceder a tutoriales sobre las funcionalidades del Portal de Compras',
    watchTutorial: 'Ver tutorial',
    watchPortalDeCompras: 'Conoce el Portal de compras',
    watchShoppingLists: 'Listas de la compra',
    watchMultiCenterSection: 'Sección multicentro',
    watchApprovalRequest: 'Solicitar aprobación de pedidos',
    watchOrderApproval: 'Aprobar solicitud de pedidos',
  },
}
